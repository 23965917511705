import React, {Fragment, useEffect, useRef, useState} from "react";
import PropTypes                                      from "prop-types";
import {Spinner}                                      from "../dom/Spinner";
import {ErrorContainer}                               from "../dom/ErrorContainer";
import {Comment}                                      from "./Comment";
import {CommentForm}                                  from "./CommentForm";
import _                                              from "lodash";

const axios = require("axios").default;

export const Comments = props => {
    const [elements, setElements] = useState(props.elements);
    const [addMode, setAddMode] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [isLoaded, setLoaded] = useState(true);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if ((!elements || elements.length <= 0) && props.load) {
            loadData();
        }
    }, []);

    const onAddButtonClick = (event) => {
        event.preventDefault();
        setAddMode(true);
    };

    const onCommentSave = () => {
        setAddMode(false);
        loadData();
    };

    const loadData = () => {
        setLoaded(false);
        let params = {};

        params.filter = "Y";
        if (props.entityType) {
            params.entity_type = props.entityType;
        }
        if (props.entityId) {
            params.entity_id = props.entityId;
        }
        if (props.userId) {
            params.user_id = props.userId;
        }

        axios.get(`/api/comments/`, {params: params})
             .then((response) => {
                 setElements(response.data);
                 setLoaded(true);
                 return response.data;
             })
             .catch((errors) => {
                 setLoaded(true);
                 setErrors(errors => [...errors, "Не удалось выполнить запрос!"]);
                 if (app.debug) {
                     throw new Error(errors);
                 }
             });
    };

    if (!isLoaded) {
        return <Spinner/>;
    }

    if (!elements) {
        return "-";
    }

    if (addMode) {
        return (
            <CommentForm
                entityType={props.entityType}
                entityId={props.entityId}
                onSave={onCommentSave}
                onCancel={() => setAddMode(false)}
            />
        );
    }

    return (
        <Fragment>
            {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}
            {!showAll && elements.length > 0 ?
                <Comment element={elements[0]}/>
                :
                <Fragment>
                    {elements?.map((comment, index) => (
                            <div key={comment.ID}>
                                <Comment element={comment}/>
                            </div>
                        ),
                    )}
                </Fragment>
            }
            <div className={"text-center"}>
                {elements.length > 1 ?
                    <div className={""}>
                        {!showAll ?
                            <a href={"#"} title={"показать все комментарии"}
                               onClick={() => setShowAll(true)}>
                                показать все ({elements.length})
                            </a>
                            :
                            <a href={"#"} title={"Скрыть все комментарии кроме последнего"}
                               onClick={() => setShowAll(false)}>
                                скрыть
                            </a>
                        }
                    </div>
                    :
                    ""
                }
                <div className={""}>
                    <a href={"#"} title={"Добавить комментарий"} onClick={onAddButtonClick}>
                        <i className="far fa-comment-dots me-2"></i>
                    </a>
                </div>
            </div>
        </Fragment>
    );

};

Comments.propTypes = {
    load: PropTypes.bool,
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    userId: PropTypes.string,
    elements: PropTypes.array,
};


